<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">出现错误</h1>
        <h2 class="subtitle">如果错误重复出现，请联系管理员</h2>
        <b-field>
          <textarea rows="10"
            class="textarea is-danger"
            type="text"
            readonly
            v-model="errorMessage"></textarea>
        </b-field>
        <hr>
        <button class="button is-danger" @click="onLogout">重新登录</button>
      </div>
    </div>
  </section>
</template>

<script>
import cache from '@/core/cache'

export default {
  created: function () {
  },
  computed: {
  },
  data: function () {
    return {
      errorMessage: cache.getValue('errorMessage') || this.$route.query.cause
    }
  },
  methods: {
    onLogout: function () {
      cache.removeValue('errorMessage')
      this.hub.logout()
    }
  }
}
</script>
